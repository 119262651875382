import styled, { themeGet } from "@styled";

export const ProgressBarWrap = styled.div`
  &:not(:last-child) {
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .heading {
    color: #343434;
    margin-block-end: 7px;
    font-weight: 400;
    font-size: 17px;
  }
  .progress {
    height: 8px;
    overflow: hidden;
    font-size: 14px;
    background-color: ${themeGet("colors.whiteSmoke")};
    border-radius: 4px;
    &-bar {
      position: relative;
      background: ${themeGet("colors.primary")};
      color: ${themeGet("colors.primary")};
      border-radius: 4px;
    }
  }
`;
